.mainViewbox {
    margin-bottom: 50px;
}

* {
    --yellow: #fcd7a7;
    --yellowPill: #FFF0DD;
    --yellowPillHover: #fcd7a7;
    --blue: #BBD5FF;
    --bluePill: #EDF4FF;
    --bluePillHover: #BBD5FF;
    --red: #FFCECE;
    --redPill: #FFE8E8;
    --redPillHover: #FFCECE;
    --purple: #e4ceff;
    --purplePill: #F0E9FF;
    --purplePillHover: #e4ceff;
    --cyan: #A5FFE1;
    --cyanPill: #F3FFFB;
    --cyanPillHover: #A5FFE1;
    --green: #FDFFAE;
    --greenPill: #FEFFE2;
    --greenPillHover: #FDFFAE;
  }
  

.clickable {
    cursor: pointer;
}

  /* Part 1  */
  .fundamentals {
    fill: #fff;
  }
  
  .fundamentals:hover {
    fill: var(--yellow);
  }

  .fundamentalsPill {
    fill: var(--yellowPill);
  }

  .fundamentalsPill:hover {
    fill: var(--yellowPillHover);
  }

   /* Part 2  */
  .discover{
    fill: #fff;
  }
  
  .discover:hover {
    fill: var(--blue);
  }

  .discoverPill {
    fill: var(--bluePill);
  }

  .discoverPill:hover {
    fill: var(--bluePillHover);
  }

   /* Part 3  */
  .define{
    fill: #fff;
  }
  
  .define:hover {
    fill: var(--red);
  }

  .definePill {
    fill: var(--redPill);
  }

  .definePill:hover {
    fill: var(--redPillHover);
  }
  
   /* Part 4 */
  .ui{
    fill: #fff;
  }
  
  .ui:hover {
    fill: var(--purple);
  }

  .uiPill {
    fill: var(--purplePill);
  }

  .uiPill:hover {
    fill: var(--purplePillHover);
  }
  
  /* Part 5 */
  .deliver{
    fill: #fff;
  }
  
  .deliver:hover {
    fill: var(--cyan);
  }

  .deliverPill {
    fill: var(--cyanPill);
  }

  .deliverPill:hover {
    fill: var(--cyanPillHover);
  }
  
  /* Part 6 */
  .career{
    fill: #fff;
  }
  
  .career:hover {
    fill: var(--green);
  }

  .careerPill {
    fill: var(--greenPill);
  }

  .careerPill:hover {
    fill: var(--greenPillHover);
  }
  